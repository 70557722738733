.main {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c9c8c8;
}

.creatorRow {
  margin-top: 44px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.creatorLabel {
  margin-left: 4px;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #52504e;
  text-decoration: none;
}

.timeAgo {
  margin-top: 12px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  color: #737373;
}

.body {
  padding: 12px 55px 12px 24px;
  margin: 33px 44px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  line-height: 18px;
  border-left: 1px solid #c9c8c8;
  color: #737373;
}

@media (max-width: 849px) {
  .body {
    padding: 12px 55px 12px 24px;
    margin: 12px 10px;
  }
}
