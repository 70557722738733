@keyframes shimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.main {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 86px;
  background: #fff;
  border: 1px solid #d4d0e6;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
}

.main:hover {
  background: #e8f4f5;
  border: 1px solid #00f0ec;
  border-radius: 2px;
}

.trendingPosition {
  width: 8px;
  margin-left: 23px;
  margin-right: 22px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #222;
}

.name,
.team {
  width: 140px;
  font-family: 'Barlow', sans-serif;
  max-height: 32px;
  overflow: hidden;
  line-height: 16px;
  font-size: 14px;
  color: #222;
}

.name {
  font-weight: 600;
}

.loading .name,
.loading .team {
  margin-top: 4px;
  width: 140px;
  height: 14px;
  border-radius: 8px;
  background-color: #797979;
  background-image: linear-gradient(to right, #797979 0%, #c9c9c9 50%, #797979 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.loading .team {
  width: 104px;
}

.imageSection {
  margin-right: 16px;
  height: 40px;
  width: 40px;
}

.playerImage {
  height: 40px;
  width: 40px;
}

.textSection {
  display: flex;
  flex-direction: column;
}
