.main {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.main h2 {
  margin: 60px 0;
  font-family: 'Bebas', sans-serif;
  font-size: 36px;
  line-height: 48px;
}

.contentRow {
  display: flex;
  flex-direction: row;
}

.contentLeft {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.contentRight {
  display: flex;
  flex-direction: column;
}

.verticalDivider {
  width: 0;
  border-right: 1px solid #c9c8c8;
}

.horizontalDivider {
  height: 0;
  border-bottom: 1px solid #c9c8c8;
}

.hero {
  color: #fff;
  background-color: #231476;
  width: 100%;
  height: 480px;
}

.heroTitle {
  margin-top: 132px;
  font-family: 'Zilla Slab', serif;
  font-size: 70px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  color: #fff;
}

.heroText {
  width: 390px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Barlow', sans-serif;
  font-size: 26px;
  line-height: 1.15;
  text-align: center;
  color: #fff;
}

@media (max-width: 1280px) {
  .main {
    margin-top: 50px;
  }

  .main h2 {
    margin: 40px 0;
  }
}

@media (max-width: 849px) {
  .main {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .hero {
    height: 300px;
  }

  .main h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .contentRow {
    display: flex;
    flex-direction: column;
  }

  .verticalDivider {
    display: none;
  }

  .heroTitle {
    font-size: 40px;
    margin-top: 83px;
  }

  .heroText {
    width: 270px;
    font-size: 18px;
    line-height: 1.22;
  }
}

@media (max-width: 600px) {
  .main {
    margin-top: 20px;
  }

  .hero {
    height: 260px;
  }

  .main h2 {
    margin: 30px 0;
  }
}
