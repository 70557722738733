.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 180px 0;
}

.gif {
  width: 208px;
  height: 198px;
}

.message {
  font-family: 'Barlow', sans-serif;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #857bb8;
  padding-bottom: 35px;
}

@media (max-width: 849px) {
  .main {
    margin: 66px 0 300px;
  }

  .gif {
    width: 100px;
    height: 96px;
  }

  .message {
    font-size: 20px;
    letter-spacing: 0.5px;
    margin-top: 12px;
    padding-bottom: 22px;
  }
}
