@keyframes shimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.main {
  padding: 8px 8px 0;
  width: 317px;
  height: 261px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  box-sizing: border-box;
  border: solid 1px transparent;
  cursor: pointer;
}

.mainMobile {
  padding: 0;
  width: 100%;
  height: 97px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-bottom: solid 1px #c9c8c8;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

.main:focus,
.mainMobile:focus {
  outline: 1px dotted #212121;
  outline: 5px auto #857bb8;
}

.main:hover {
  border-radius: 2px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #c9c8c8;
  background-color: #fff;
}

.main h4 {
  height: 18px;
  width: 300px;
  margin: 12px 0 0 0;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #222;
}

.mainMobile h4 {
  margin: 0 0 0 12px;
  width: 140px;
  height: 32px;
  font-size: 14px;
  overflow: hidden;
  white-space: normal;
  text-overflow: unset;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  line-height: 1.13;
  color: #222;
}

.loading h4,
.mobileLoading h4 {
  width: 290px;
  height: 16px;
  border-radius: 8px;
  background-color: #222;
  background-image: linear-gradient(to right, #222 0%, #c9c9c9 50%, #222 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.mobileLoading h4 {
  width: 160px;
}

.cardHeaders {
  display: flex;
  flex-direction: column;
}

.main h5 {
  margin: 24px 0 0 0;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 1.14;
  color: #52504e;
}

.mainMobile h5 {
  margin: 4px 0 0 12px;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 1.14;
  font-weight: normal;
  color: #52504e;
}

.loading h5,
.mobileLoading h5 {
  width: 80px;
  height: 14px;
  border-radius: 8px;
  background-color: #222;
  background-image: linear-gradient(to right, #52504e 0%, #c9c9c9 50%, #52504e 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.cardImg {
  width: 300px;
  height: 170px;
  cursor: pointer;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #d4d0e6;
}

.cardImgMobile {
  margin-left: 4px;
  width: 100px;
  height: 56px;
  cursor: pointer;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: #d4d0e6;
}

.imgOverlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  outline: none;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.6));
}

.loading .imgOverlay {
  background: transparent;
}

.playButton {
  margin-top: 117px;
  margin-left: 248px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
}

.loading .playButton {
  display: none;
}

.main:hover .playButton {
  display: none;
}

.main:hover .imgOverlay {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}
