.main {
  margin-bottom: 58px;
  min-width: 263px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.main h3 {
  margin: 0 0 44px;
  font-family: 'Bebas', sans-serif;
  font-size: 36px;
  line-height: 28px;
  color: #222;
}

.buttonLink {
  margin-top: 36px;
}

@media (max-width: 849px) {
  .main {
    padding-left: 0;
    margin-bottom: 35px;
  }

  .main h3 {
    margin: 19px 0;
  }

  .buttonLink {
    align-self: center;
    margin-top: 32px;
    width: 60%;
  }
}
