.main {
  margin-top: 55px;
  margin-bottom: 129px;
  width: 300px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.main h3 {
  margin: 0 0 44px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #222;
}

.videoCard {
  cursor: pointer;
}

@media (max-width: 849px) {
  .main {
    width: unset;
    margin-top: 19px;
    padding-left: 0;
    margin-bottom: 35px;
  }

  .main h3 {
    margin: 19px 0;
  }

  .videoCard {
    width: 100%;
    align-items: center;
  }
}
