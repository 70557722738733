.main {
  position: relative;
  color: #fff;
  background-color: #231476;
  width: 100%;
  height: 540px;
}

.bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.bgContents {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  /* doesn't work in msie11 https://caniuse.com/#search=object-fit */
  object-fit: cover;
}

.bgOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(219deg, #231476, #000);
  opacity: 0.65;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 849px) {
  .main {
    height: 518px;
    padding-top: 0;
  }
}
