.main {
  border-bottom: solid 1px #d4d0e6;
  font-family: 'Barlow', sans-serif;
  margin: 0 0 7px;
}

.ToggleButton {
  background: none;
  width: 100%;
  padding: 21px 0 10px 0;
  border: 0;
  margin: 0;
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ToggleButton:focus {
  outline: 0;
}

.ToggleButtonIcon {
  width: 22px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ToggleButtonIcon div {
  box-sizing: border-box;
  height: 1px;
  border: solid 1px #857bb8;
}
.ToggleButtonIcon div:first-child { width: 100%; }
.ToggleButtonIcon div:nth-child(2) { width: 58%; }
.ToggleButtonIcon div:last-child { width: 39%; }

.ToggleButtonLabel {
  margin-left: 3px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #231476;
}

.body {
  padding: 20px 0 36px 0;
}

.Options {
  display: flex;
  margin-right: -26px;
}

.OptionGroup {
  min-width: 168px;
  margin-right: 26px;
}

.OptionGroupName {
  font-size: 14px;
  font-weight: 500;
  color: #222;
  padding-bottom: 3px;
  border-bottom: solid 1px #d4d0e6;
  margin-bottom: 6px;
}

.OptionGroupBody {
  padding: 0;
  margin: -3px 0;
}

.OptionGroupOption {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #857bb8;
  background: none;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.OptionGroupOptionSelected {
  font-weight: bold;
  color: #231476;
}

.OptionGroupOptionLabel {
  padding: 3px 0;
}

.OptionGroupOptionX {
  color: #231476;
  display: none;
  font-size: 16px;
}

.OptionGroupOptionSelected .OptionGroupOptionX {
  display: block;
}

@media (max-width: 940px) {
  .main {
    margin: 0 5px 7px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 0 7px;
  }

  .Options {
    flex-direction: column;
    margin-right: 0;
  }

  .OptionGroup {
    min-width: auto;
    margin-right: 0;
  }

  .OptionGroupBody {
    margin: 0 0 24px 0;
  }

  .OptionGroupOption {
    padding: 0;
    margin: 0;
  }

  .OptionGroupOptionLabel {
    padding: 6px 0 6px 7px;
  }

  .OptionGroupOptionX {
    font-size: 20px;
  }
}
