.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailsSect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logoImg {
  width: 75%;
  height: 75%;
  background-size: contain;
}

.playerImg {
  background-size: cover;
}

.imageBorder {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  margin-bottom: 18px;
  border-color: #332387;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boldText {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 11px;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #473e2e;
}

.greyText {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #8c8c8c;
}

/* Mobile styles */

@media (max-width: 849px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detailsSect {
    align-items: unset;
    margin-left: 18px;
  }

  .imageBorder {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
  }

  .boldText {
    font-size: 16px;
    margin-bottom: 0;
  }

  .greyText {
    font-size: 12px;
    margin-bottom: 0;
  }
}
