.main {
  height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.creatorImage {
  margin: 0;
  margin-right: 24px;
  height: 48px;
  width: 48px;
}

.displayNameLink,
.displayName,
.publishedAt,
.creatorLabel {
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #6148e5;
  text-decoration: none;
}

.displayName,
.creatorLabel {
  color: #52504e;
}

.creatorLabel {
  margin-left: 4px;
}

.publishedAt {
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
  color: #52504e;
}

@media (max-width: 570px) {
  .main {
    height: 28px;
  }

  .creatorImage {
    margin: 0;
    margin-right: 16px;
    height: 28px;
    width: 28px;
  }
}
