.inlinePlayer {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.noVideo {
  width: 99%;
  margin: 0;
  padding: 0;
  padding-top: 56.25%;
  background-color: #fff;
  border: 1px solid #c9c8c8;
}

.image {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* insert element at begining of player so there's a minimum
height with ratio 16:9
*/
.inlinePlayer::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  float: left;
}

.imageOverlay {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.imageOverlayInner {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  font-size: 64px;
}

.inlinePlayer:hover .imageOverlay {
  display: block;
}

.player {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
}

@media (max-width: 849px) {
  .inlinePlayer:hover .imageOverlay {
    display: none;
  }
}
