@keyframes shimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c9c8c8;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.showCard {
  margin-left: 36px;
  margin-right: 36px;
  width: 12vw;
  height: 16vw;
}

.title {
  margin-top: 22px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #222;
  text-decoration: none;
}

.timeAgo {
  margin-top: 12px;
  margin-bottom: 53px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  color: #737373;
}

.loading .title,
.loading .timeAgo {
  width: 60%;
  height: 16px;
  border-radius: 7px;
  background-color: #c8c8c8;
  background-image: linear-gradient(to right, #c8c8c8 0%, #eee 50%, #c8c8c8 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.loading .timeAgo {
  width: 100px;
}

.video {
  flex: 1;
  margin-top: 36px;
}

.articleImageWrapper {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.articleImage {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 56.25%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.articleIcon {
  position: absolute;
  right: 32px;
  bottom: 23px;
  height: 72px;
  width: 72px;
}

.showName {
  display: none;
}

.mobileNameRow {
  display: flex;
  flex-direction: row;
}

.followButton {
  margin-left: 20px;
  display: inline;
}

@media (max-width: 849px) {
  .title {
    margin-top: 19px;
  }

  .showCard {
    display: none;
  }

  .mobileNameRow {
    margin-top: 19px;
  }

  .showName {
    display: inline-block;
    font-weight: 500;
    color: #231476;
    text-decoration: none;
    font-family: 'Barlow', sans-serif;
    line-height: 1.13;
  }

  .video {
    margin-top: 28px;
  }

  .articleIcon {
    right: 16px;
    bottom: 16px;
    height: 36px;
    width: 36px;
  }

  .timeAgo {
    margin-top: 19px;
    margin-bottom: 19px;
  }
}
