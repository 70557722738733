.main {
  background-color: #222;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 8px;
  min-height: 214px;
}

.footerTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.socialIcons {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footerLinks {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.link {
  margin: 14px 10px 0;
  font-family: 'Barlow', sans-serif;
  min-width: 64px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #fafafc;
  text-decoration: none;
}

.smallText,
.contactLink {
  margin-top: 16px;
  width: 320px;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  align-self: flex-start;
  color: #fafafc;
}

.xmlTeamLink {
  text-decoration: none;
  color: #00dde2;
}

.sportradar {
  margin-top: 95px;
  text-decoration: none;
  align-self: flex-start;
}

.sportradar img {
  width: 171px;
  height: 36px;
}

/* Mobile styles */
@media (max-width: 849px) {
  .footerTop {
    display: block;
  }

  .socialIcons {
    display: block;
    margin-top: 64px;
    margin-left: 0;
  }

  .footerLinks {
    flex: none;
    flex-direction: column;
  }

  .footer {
    padding: 30px 20px;
    min-height: 214px;
  }

  .link {
    margin: 16px 0 0;
    color: #fff;
  }

  .link:first-child {
    margin-top: 0;
  }

  .smallText,
  .xmlTeamLink {
    width: 280px;
    margin-top: 16px;
    font-size: 10px;
  }

  .sportradar {
    margin-top: 64px;
  }
}
