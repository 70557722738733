.main {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Barlow', sans-serif;
  color: #fff;
  background: #222;
  background-size: cover;
  background-position: center center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 3px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #8c8c8c;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.imageOrTitle {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.title {
  padding: 16px;
  text-align: center;
}

.cornerButton {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 15px 19px;
  border: 0;
  margin: 0;
  background: transparent;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #e1e0e6;
  cursor: pointer;
}

.playButton {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  font-size: 46px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1;
  color: #e1e0e6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playOverlay {
  transition: visibility 0s, opacity 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  visibility: collapse;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playOverlayVisible {
  opacity: 1;
  visibility: visible;
}

.playOverlayOverlayed .playButton {
  display: none;
}

.playOverlayOverlayed .cornerButton {
  display: none;
}

.description {
  transition: visibility 0s, top 0.1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  visibility: collapse;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  padding: 51px 12px;
  font-size: 14px;
  font-weight: 500;
  overflow: scroll;
}

.descriptionVisible {
  position: absolute;
  visibility: visible;
  left: 0;
  top: 0%;
}

@media (max-width: 849px) {
  .main {
    width: 100%;
    height: 100%;
    border-radius: 1.9px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border: solid 0.6px #8c8c8c;
  }

  .title {
    padding: 12px;
    text-align: center;
    font-size: 20px;
  }

  .cornerButton {
    right: 0;
    top: 0;
    padding: 8px 8px 24px 32px;
  }

  .description {
    padding: 32px 8px;
    font-size: 12px;
  }

  .playButton {
    height: calc(100% - 75px);
  }
}
