@font-face {
  font-family: 'Bebas';
  src: local('Bebas'), url('./fonts/bebas.ttf') format('truetype');
}

.main {
  background: #fdfdfd;
  background-color: #fdfdfd;
}

.heroAndMain {
  min-height: 900px;
}

button,
input,
a {
  outline-color: #857bb8;
}
