
.searchRow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.searchRowMobile {
  width: 100%;
}

.searchIconButton {
  position: absolute;
  z-index: 100;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #52504e;
}

.searchIcon {
  font-size: 18px;
  padding: 1px;
}

.searchCloseIconButton {
  padding: 0 6px;
  visibility: hidden;
  transition: visibility 0.25s;
  outline: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.searchCloseIcon {
  font-size: 24px;
  color: #857bb8;
}

.searchInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: width 0.25s;
  outline: none;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  box-sizing: border-box;
  width: 256px;
  height: 40px;
  padding: 0 36px 0 11px;
  border-radius: 2px;
  border: 1px solid #d4d0e6;
}

.searchRowMobile .searchInput {
  width: 100%;
  height: 100%;
  border: 0;
}

.searchRowMobile .searchCloseIconButton {
  visibility: visible;
  position: unset;
}

.searchRowMobile .searchIcon {
  font-size: 18px;
}

@media (max-width: 320px) {
  .searchRowMobile .searchIconButton {
    right: 2px;
  }
}

.searchRowShowInputHidden .searchCloseIconButton {
  visibility: hidden;
}

.searchRowShowInputHidden .searchInput {
  border-width: 0;
  width: 0;
  padding: 0;
}
