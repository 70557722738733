.main {
  width: 240px;
  box-sizing: border-box;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  border: solid 1px #222;
  background-color: #222;
  padding: 11px 32px;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.main:disabled {
  color: #8c8c8c;
  background-color: #e5e5ea;
  border: solid 1px #8c8c8c;
  cursor: default;
}

.invert {
  color: #222;
  border: solid 1px #c9c8c8;
  background-color: #fff;
}

.small {
  border-radius: 15px;
  padding: 6px 40px;
  width: auto;
}
