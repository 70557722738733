.fullHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 94px;
  background-color: #fff;
  box-shadow: 0 8px 8px -7px #0033;
}

.loggedInGroup {
  display: flex;
  flex-direction: row-reverse;
}

.main {
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-top: 1px solid #c9c8c8;
}

.nextOnesTitle {
  margin-left: 40px;
  font-family: 'Bebas', 'Barlow', sans-serif;
  text-transform: uppercase;
  color: #222;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.43px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navBar {
  display: flex;
  justify-content: center;
  height: 58px;
  background-color: #fff;
  padding: 0;
  color: #6148e5;
}

.navTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.225px;
  color: #6148e5;
  text-decoration: none;
  padding-bottom: 2px;
}

.topLink {
  padding: 9px 0 9px 24px;
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration: none;
  color: #52504e;
}

@media (max-width: 940px) {
  .topBar {
    padding: 0 16px;
  }

  .navTab {
    padding: 0;
  }

  .nextOnesTitle {
    padding: 0;
  }
}

/* Mobile styles */
@media (max-width: 849px) {
  .navBar {
    height: 50px;
    border-bottom: 0;
  }

  .navTab {
    font-size: 12px;
    padding: 0 11px;
  }
}

@media (max-width: 570px) {
  .navTab {
    padding: 0 8px;
  }

  .inputVisible .navTab {
    display: none;
  }
}

@media (max-width: 320px) {
  .navTab {
    font-size: 11px;
  }
}

.activeNavTab {
  color: #231476;
  border-bottom: 2px solid #231476;
  padding-bottom: 0;
}

.mobileDrawerContainer,
.mobileDrawerContainerHero {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  margin: 0 auto;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 8px 8px -7px #0033;
}

.mobileDrawerContainerHero {
  background-color: #fcfcff;
}

.mobileNavTabContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
}

.mobileNavTab {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #6148e5;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  border: none;
}

.mobileNavTab:last-child {
  border: none;
}

.mobileLogo {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 14px 10px 20px;
  box-shadow: 0 8px 8px #0033;
  z-index: 100;
}

.mobileLogoHero,
.mobileLogoHeroOpen {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  padding: 20px 14px 10px 20px;
  z-index: 1000;
}

.mobileLogoHeroOpen {
  background-color: #fcfcff;
}

.mobileBarsIcon {
  cursor: pointer;
  color: #737373;
  align-self: center;
  font-size: 18px;
}

.mobileRightSect {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 21px;
  border-left: 1px solid #d4d0e6;
}
