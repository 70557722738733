.main {
  display: flex;
  flex-direction: column;
}

.main h3 {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #222;
}

.showMore {
  margin: 36px 0;
  align-self: center;
}

.noShows {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}

.smallText {
  max-width: 440px;
  margin-top: 8px;
  margin-bottom: 24px;
  line-height: 18px;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  text-align: left;
  color: #737373;
}

@media (max-width: 1280px) {
  .main h3 {
    margin: 0;
  }
}
