.main {
  padding: 0 120px 0;
  margin: 0 auto;
  overflow: hidden;
}

@media (max-width: 1230px) {
  .main {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 940px) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}
