@keyframes shimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.main {
  margin-left: 36px;
  margin-right: 36px;
  padding: 1.2vw 2px;
  width: 12vw;
  height: 16vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  border: solid 1px transparent;
}

.main:focus {
  outline: 1px dotted #212121;
  outline: 5px auto #857bb8;
}

.main:hover {
  border-radius: 2px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border: solid 1px #c9c8c8;
}

.link {
  text-decoration: none;
}

.creatorImage {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1vw;
  width: 6vw;
  height: 6vw;
  border-color: #c9c8c8;
}

.name,
.type {
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.14;
  text-align: center;
  color: #222;
}

.loading .name {
  width: 10vw;
  height: 16px;
  border-radius: 8px;
  background-color: #222;
  background-image: linear-gradient(to right, #6b6b6b 0%, #c9c9c9 50%, #6b6b6b 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.type {
  margin-top: 0.4vw;
  text-transform: capitalize;
  text-align: center;
  color: #737373;
}

.loading .type {
  width: 6vw;
  height: 16px;
  border-radius: 8px;
  background-color: #222;
  background-image: linear-gradient(to right, #979797 0%, #dad8d8 50%, #979797 100%);
  background-size: 1000px 100px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

.followContainer {
  margin-top: auto;
  margin-bottom: 6px;
  display: none;
  width: 84%;
}

.main:hover .followContainer {
  display: block;
}

@media (max-width: 1250px) {
  .name,
  .type {
    font-size: 15px;
  }
}

@media (max-width: 1100px) {
  .name {
    word-break: break-all;
    font-size: 14px;
  }

  .type {
    display: none;
  }
}
