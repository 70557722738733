.main {
  width: 100%;
}

.linkMain {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  color: #6148e5;
}

@media (max-width: 1250px) {
  .linkMain {
    font-size: 15px;
  }
}

@media (max-width: 1100px) {
  .linkMain {
    font-size: 14px;
  }
}

@media (max-width: 849px) {
  .linkMain {
    margin: 0;
    font-size: 16px;
  }
}
